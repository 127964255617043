// @import 'src/styles/_variables';
@font-face {
  font-family: "Lora";
  src: local("Lora"),
    url("../../../fonts/Lora-VariableFont_wght.ttf") format("truetype");
}

@font-face {
  font-family: "Lora-Italic";
  src: local("Lora-Italic"),
    url("../../../fonts/Lora-VariableFont_wght.ttf") format("truetype");
}

.certificatePrintButton {
  border: none;
  position: fixed;
  top: 30px;
  left: 30px;
  z-index: 1000000;
  border-radius: 50%;
  background: white;
  box-shadow: 0 0.4em #808080, 0 0.7em rgba(0, 0, 0, 0.4);
  cursor: pointer;
}
.certificatePrintButton:active {
  top: 32px;
  box-shadow: 0 0.2em #808080, 0 0.5em rgba(0, 0, 0, 0.4);
}

.certificateExternalContainer {
  text-align: center;
  position: relative;
  margin-top: 50px;
  margin-bottom: 50px;
}

.certificateContainer {
  margin: auto;
  height: 750px;
  width: 1100px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  position: relative;
  -webkit-print-color-adjust: exact;
  font-family: Lora-Italic;
  font-weight: 700;
}

.certificateOwner {
  position: absolute;
  left: 42%;
  top: 23.5%;
}

.certificateSubject {
  position: absolute;
  left: 42%;
  top: 31.4%;
}

.certificateSpecie {
  position: absolute;
  left: 42%;
  top: 34.7%;
}

.certificateMutation {
  position: absolute;
  left: 42%;
  top: 38.2%;
}

.certificateBorn {
  position: absolute;
  left: 42%;
  top: 41.4%;
}

.certificateDate {
  position: absolute;
  left: 36%;
  top: 68.5%;
}

.certificateLogo {
  position: absolute;
  left: 7%;
  top: 7.5%;
  width: 200px;
  height: 100px;
}

.certificateResult {
  position: absolute;
  left: 42%;
  top: 56%;
  font-size: 2rem;
}

.certificateLogoImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media print {
  body {
    visibility: hidden;
  }
  .certificateExternalContainer {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .certificateContainer {
    visibility: visible;
  }

  .certificateContainer {
    position: absolute;
    left: 0;
    top: 0;
    -webkit-print-color-adjust: exact;
  }
}
