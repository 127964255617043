.logo {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filtersCol {
  padding: 0 5px 0;
}

.filtersCol .ant-form-item {
  margin-bottom: 10px;
}
